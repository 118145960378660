<template>
  <div>
    <div class="top">
      <img src="../../../../static/image/left.png" @click="back" alt="" />
    </div>

    <div class="add">添加银行卡</div>
    <div class="add_text">请绑定持卡人本人银行卡</div>
    <div class="list">
      <div class="list_box">
        <span>持卡人</span>
        <input type="text" v-model="name" placeholder="持卡人姓名" />
      </div>
      <div class="list_box">
        <span>卡号</span
        ><input
          type="text"
          @input="specifiName($event)"
          v-model="number"
          placeholder="持卡人本人卡号"
        />
      </div>
      <div class="list_box">
        <span>卡类型</span>
        <div>
          <input v-model="type" type="text" placeholder="卡的类型" />
        </div>
      </div>
      <div class="list_box">
        <span>所属银行</span>
        <div>
          <input v-model="bankName" type="text" placeholder="所属银行" />
        </div>
      </div>
      <div class="select">
        <span>开卡城市 </span><input v-model="bankProvice" type="text" /><img
          @click="show = true"
          src="../../../../static/image/right.png"
          alt=""
        />
      </div>
      <div class="list_box">
        <span>开户行</span
        ><input type="text" v-model="bankAddress" placeholder="请输入开户行" />
      </div>
      <div class="list_box">
        <span>手机号</span
        ><input type="text" v-model="tel" placeholder="请输入手机号" />
      </div>

      <div class="next" @click="but">下一步</div>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
          <van-area
            title="请选择卡所在省市"
            @confirm="confirm"
            @cancel="cancel"
            :area-list="areaList"
            :columns-num="2"
          />
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
export default {
  data() {
    return {
      searchResult: [],
      areaList,
      show: false,
      name: '', // 持卡人
      number: '', //卡号
      type: '', // 类型
      bankProvice: '', //省市
      bankAddress: '', //开户行
      tel: '', // 手机号
      bankName: '', // 所属银行
      addrCode: '',
      flowId: '',
    };
  },
  methods: {
    // 地址栏取消、
    cancel() {},
    // 地址栏确定
    confirm(e) {
      let arr = e[0].name;
      let res = e[1].name;
      this.bankProvice = arr + '/' + res;
      this.show = false;
    },
    // 持卡人本人卡号
    specifiName(e) {
      // var that = this;
      var val = e.target.value;
      if (val.length >= 16 && val.length <= 19) {
        let params = {
          no: this.number,
        };
        this.$api.anyBankname(params).then((res) => {
          this.type = res.data.typeName;
          this.bankName = res.data.bankName;
        });
      }
    },
    but() {
      let arr = this.bankProvice;
      let reg = arr.split('/');
      let params = {
        bankName: this.bankName,
        number: this.number,
        type: this.type,
        bankProvice: reg[0],
        bankCity: reg[1],
        bankAddress: this.bankAddress,
        tel: this.tel,
        token: localStorage.getItem('token'),
      };
      this.$api.anyBankcardadd(params).then((res) => {
        if (res.code == '0') {
          this.flowId = res.data.flowId;
          this.$router.push({
            path: '/verification',
            query: { num: params, flowId: this.flowId },
          });
        } else if (res.code == '0002') {
          this.$toast.fail('绑定银行卡信息失败');
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    ///返回上一页
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  position: absolute;
  width: 100%;
  // top: 50px;
  bottom: 0px;
}

/deep/.van-picker__confirm {
  color: #0754d3;
}
/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}
.list {
  .select {
    display: flex;
    align-items: center;
    margin-left: 34px;
    margin-right: 32px;
    // border-bottom: 1px #eeeeee solid;
    height: 55px;
    font-size: 15px;
    justify-content: space-between;
    input {
      font-size: 14px;
      border: 0;
    }
    img {
      display: block;
      width: 7px;
      height: 12px;
      padding-right: 5px;
    }
  }
  .list_box {
    display: flex;
    align-items: center;
    margin-left: 34px;
    margin-right: 32px;
    border-bottom: 1px #f8f8f8 solid;
    height: 55px;
    span {
      font-size: 15px;
      display: block;
      width: 80px;
    }
    input {
      font-size: 14px;
      border: 0;
    }
  }
}
.add {
  font-size: 18px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
}
.add_text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 21px;
  background-color: #ffffff;
  img {
    width: 11px;
    height: 20px;
  }
}
.next {
  width: 177px;
  height: 45px;
  font-size: 15px;
  background-color: #f8f8f8;
  text-align: center;
  line-height: 45px;
  margin: 0 auto;
  color: #666666;
  border-radius: 5px;
  margin-top: 30px;
}
</style>
